import { loadState } from '@nextcloud/initial-state';
import NcListItem from '@nextcloud/vue/dist/Components/NcListItem.js';
import NcLoadingIcon from '@nextcloud/vue/dist/Components/NcLoadingIcon.js';
const versionHash = loadState('core', 'versionHash', '');
export default {
  name: 'AccountMenuEntry',
  components: {
    NcListItem,
    NcLoadingIcon
  },
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    href: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    iconSource() {
      return `${this.icon}?v=${versionHash}`;
    }
  },
  methods: {
    handleClick() {
      this.loading = true;
    }
  }
};