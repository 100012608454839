import { subscribe, unsubscribe } from '@nextcloud/event-bus';
import { loadState } from '@nextcloud/initial-state';
import { n, t } from '@nextcloud/l10n';
import { useElementSize } from '@vueuse/core';
import { defineComponent, ref } from 'vue';
import AppMenuEntry from "./AppMenuEntry.vue";
import NcActions from '@nextcloud/vue/dist/Components/NcActions.js';
import NcActionLink from '@nextcloud/vue/dist/Components/NcActionLink.js';
import logger from "../logger";
export default defineComponent({
  name: 'AppMenu',
  components: {
    AppMenuEntry,
    NcActions,
    NcActionLink
  },
  setup() {
    const appMenu = ref();
    const {
      width: appMenuWidth
    } = useElementSize(appMenu);
    return {
      t,
      n,
      appMenu,
      appMenuWidth
    };
  },
  data() {
    const appList = loadState('core', 'apps', []);
    return {
      appList
    };
  },
  computed: {
    appLimit() {
      const maxApps = Math.floor(this.appMenuWidth / 50);
      if (maxApps < this.appList.length) {
        // Ensure there is space for the overflow menu
        return Math.max(maxApps - 1, 0);
      }
      return maxApps;
    },
    mainAppList() {
      return this.appList.slice(0, this.appLimit);
    },
    popoverAppList() {
      return this.appList.slice(this.appLimit);
    }
  },
  mounted() {
    subscribe('nextcloud:app-menu.refresh', this.setApps);
  },
  beforeDestroy() {
    unsubscribe('nextcloud:app-menu.refresh', this.setApps);
  },
  methods: {
    setNavigationCounter(id, counter) {
      const app = this.appList.find(_ref => {
        let {
          app
        } = _ref;
        return app === id;
      });
      if (app) {
        this.$set(app, 'unread', counter);
      } else {
        logger.warn(`Could not find app "${id}" for setting navigation count`);
      }
    },
    setApps(_ref2) {
      let {
        apps
      } = _ref2;
      this.appList = apps;
    }
  }
});