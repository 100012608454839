import axios from '@nextcloud/axios';
import Contacts from 'vue-material-design-icons/Contacts.vue';
import debounce from 'debounce';
import { getCurrentUser } from '@nextcloud/auth';
import { generateUrl } from '@nextcloud/router';
import Magnify from 'vue-material-design-icons/Magnify.vue';
import NcButton from '@nextcloud/vue/dist/Components/NcButton.js';
import NcEmptyContent from '@nextcloud/vue/dist/Components/NcEmptyContent.js';
import NcHeaderMenu from '@nextcloud/vue/dist/Components/NcHeaderMenu.js';
import NcLoadingIcon from '@nextcloud/vue/dist/Components/NcLoadingIcon.js';
import { translate as t } from '@nextcloud/l10n';
import Contact from "../components/ContactsMenu/Contact.vue";
import logger from "../logger.js";
import Nextcloud from "../mixins/Nextcloud.js";
import NcTextField from '@nextcloud/vue/dist/Components/NcTextField.js';
export default {
  name: 'ContactsMenu',
  components: {
    Contact,
    Contacts,
    Magnify,
    NcButton,
    NcEmptyContent,
    NcHeaderMenu,
    NcLoadingIcon,
    NcTextField
  },
  mixins: [Nextcloud],
  data() {
    const user = getCurrentUser();
    return {
      contactsAppEnabled: false,
      contactsAppURL: generateUrl('/apps/contacts'),
      contactsAppMgmtURL: generateUrl('/settings/apps/social/contacts'),
      canInstallApp: user.isAdmin,
      contacts: [],
      loadingText: undefined,
      error: false,
      searchTerm: ''
    };
  },
  methods: {
    async handleOpen() {
      await this.getContacts('');
    },
    async getContacts(searchTerm) {
      if (searchTerm === '') {
        this.loadingText = t('core', 'Loading your contacts …');
      } else {
        this.loadingText = t('core', 'Looking for {term} …', {
          term: searchTerm
        });
      }

      // Let the user try a different query if the previous one failed
      this.error = false;
      try {
        const {
          data: {
            contacts,
            contactsAppEnabled
          }
        } = await axios.post(generateUrl('/contactsmenu/contacts'), {
          filter: searchTerm
        });
        this.contacts = contacts;
        this.contactsAppEnabled = contactsAppEnabled;
        this.loadingText = undefined;
      } catch (error) {
        logger.error('could not load contacts', {
          error,
          searchTerm
        });
        this.error = true;
      }
    },
    onInputDebounced: debounce(function () {
      this.getContacts(this.searchTerm);
    }, 500),
    /**
     * Reset the search state
     */
    onReset() {
      this.searchTerm = '';
      this.contacts = [];
      this.focusInput();
    },
    /**
     * Focus the search input on next tick
     */
    focusInput() {
      this.$nextTick(() => {
        this.$refs.contactsMenuInput.focus();
        this.$refs.contactsMenuInput.select();
      });
    }
  }
};