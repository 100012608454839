// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * SPDX-FileCopyrightText: 2017 Nextcloud GmbH and Nextcloud contributors
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
.oc-dialog {
  background: var(--color-main-background);
  color: var(--color-text-light);
  border-radius: var(--border-radius-large);
  box-shadow: 0 0 30px var(--color-box-shadow);
  padding: 24px;
  z-index: 100001;
  font-size: 100%;
  box-sizing: border-box;
  min-width: 200px;
  top: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 20px);
  max-width: calc(100% - 20px);
  overflow: auto;
}

.oc-dialog-title {
  background: var(--color-main-background);
}

.oc-dialog-buttonrow {
  position: relative;
  display: flex;
  background: transparent;
  right: 0;
  bottom: 0;
  padding: 0;
  padding-top: 10px;
  box-sizing: border-box;
  width: 100%;
  background-image: linear-gradient(rgba(255, 255, 255, 0), var(--color-main-background));
}
.oc-dialog-buttonrow.twobuttons {
  justify-content: space-between;
}
.oc-dialog-buttonrow.onebutton, .oc-dialog-buttonrow.twobuttons.aside {
  justify-content: flex-end;
}
.oc-dialog-buttonrow button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 44px;
  min-width: 44px;
}

.oc-dialog-close {
  position: absolute;
  width: 44px !important;
  height: 44px !important;
  top: 4px;
  right: 4px;
  padding: 25px;
  background: var(--icon-close-dark) no-repeat center;
  opacity: 0.5;
  border-radius: var(--border-radius-pill);
}
.oc-dialog-close:hover, .oc-dialog-close:focus, .oc-dialog-close:active {
  opacity: 1;
}

.oc-dialog-dim {
  background-color: #000;
  opacity: 0.2;
  z-index: 100001;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.theme--dark .oc-dialog-dim {
  opacity: 0.8;
}

.oc-dialog-content {
  width: 100%;
  max-width: 550px;
}

.oc-dialog.password-confirmation .oc-dialog-content {
  width: auto;
}
.oc-dialog.password-confirmation .oc-dialog-content input[type=password] {
  width: 100%;
}
.oc-dialog.password-confirmation .oc-dialog-content label {
  display: none;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
