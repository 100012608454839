// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-menu-icon[data-v-54226706] {
  box-sizing: border-box;
  position: relative;
  height: 20px;
  width: 20px;
}
.app-menu-icon__icon[data-v-54226706] {
  transition: margin 0.1s ease-in-out;
  height: 20px;
  width: 20px;
  filter: var(--background-image-invert-if-bright);
}
.app-menu-icon__unread[data-v-54226706] {
  color: var(--color-error);
  position: absolute;
  inset-block-end: 15px;
  inset-inline-end: -5px;
  transition: all 0.1s ease-in-out;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
