// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-menu-entry:hover .app-menu-entry__icon,
.app-menu-entry:focus-within .app-menu-entry__icon,
.app-menu__list:hover .app-menu-entry__icon,
.app-menu__list:focus-within .app-menu-entry__icon {
  margin-block-end: 1lh;
}
.app-menu-entry:hover .app-menu-entry__label,
.app-menu-entry:focus-within .app-menu-entry__label,
.app-menu__list:hover .app-menu-entry__label,
.app-menu__list:focus-within .app-menu-entry__label {
  opacity: 1;
}
.app-menu-entry:hover .app-menu-entry--active::before,
.app-menu-entry:focus-within .app-menu-entry--active::before,
.app-menu__list:hover .app-menu-entry--active::before,
.app-menu__list:focus-within .app-menu-entry--active::before {
  opacity: 0;
}
.app-menu-entry:hover .app-menu-icon__unread,
.app-menu-entry:focus-within .app-menu-icon__unread,
.app-menu__list:hover .app-menu-icon__unread,
.app-menu__list:focus-within .app-menu-icon__unread {
  opacity: 0;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
