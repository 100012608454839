var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("NcListItem", {
    staticClass: "account-menu-entry",
    attrs: {
      id: _vm.href ? undefined : _vm.id,
      "anchor-id": _vm.id,
      active: _vm.active,
      compact: "",
      href: _vm.href,
      name: _vm.name,
      target: "_self"
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_c("img", {
          staticClass: "account-menu-entry__icon",
          class: {
            "account-menu-entry__icon--active": _vm.active
          },
          attrs: {
            src: _vm.iconSource,
            alt: ""
          }
        })];
      },
      proxy: true
    }, _vm.loading ? {
      key: "indicator",
      fn: function () {
        return [_c("NcLoadingIcon")];
      },
      proxy: true
    } : null], null, true)
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };