// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-menu[data-v-5dc6d664] {
  --app-menu-entry-growth: calc(var(--default-grid-baseline) * 4);
  display: flex;
  flex: 1 1;
  width: 0;
}
.app-menu__list[data-v-5dc6d664] {
  display: flex;
  flex-wrap: nowrap;
  margin-inline: calc(var(--app-menu-entry-growth) / 2);
}
.app-menu__overflow[data-v-5dc6d664] {
  margin-block: auto;
}
.app-menu__overflow[data-v-5dc6d664] .button-vue--vue-tertiary {
  opacity: 0.7;
  margin: 3px;
  filter: var(--background-image-invert-if-bright);
  /* Remove all background and align text color if not expanded */
}
.app-menu__overflow[data-v-5dc6d664] .button-vue--vue-tertiary:not([aria-expanded=true]) {
  color: var(--color-background-plain-text);
}
.app-menu__overflow[data-v-5dc6d664] .button-vue--vue-tertiary:not([aria-expanded=true]):hover {
  opacity: 1;
  background-color: transparent !important;
}
.app-menu__overflow[data-v-5dc6d664] .button-vue--vue-tertiary:focus-visible {
  opacity: 1;
  outline: none !important;
}
.app-menu__overflow-entry[data-v-5dc6d664] .action-link__icon {
  filter: var(--background-invert-if-bright) !important;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
