var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("span", {
    staticClass: "app-menu-icon",
    attrs: {
      role: "img",
      "aria-hidden": _setup.ariaHidden,
      "aria-label": _setup.ariaLabel
    }
  }, [_c("img", {
    staticClass: "app-menu-icon__icon",
    attrs: {
      src: _vm.app.icon,
      alt: ""
    }
  }), _vm._v(" "), _vm.app.unread ? _c(_setup.IconDot, {
    staticClass: "app-menu-icon__unread",
    attrs: {
      size: 10
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };