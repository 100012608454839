import NcActionLink from '@nextcloud/vue/dist/Components/NcActionLink.js';
import NcActionText from '@nextcloud/vue/dist/Components/NcActionText.js';
import NcActions from '@nextcloud/vue/dist/Components/NcActions.js';
import NcAvatar from '@nextcloud/vue/dist/Components/NcAvatar.js';
export default {
  name: 'Contact',
  components: {
    NcActionLink,
    NcActionText,
    NcActions,
    NcAvatar
  },
  props: {
    contact: {
      required: true,
      type: Object
    }
  },
  computed: {
    actions() {
      if (this.contact.topAction) {
        return [this.contact.topAction, ...this.contact.actions];
      }
      return this.contact.actions;
    },
    preloadedUserStatus() {
      if (this.contact.status) {
        return {
          status: this.contact.status,
          message: this.contact.statusMessage,
          icon: this.contact.statusIcon
        };
      }
      return undefined;
    }
  }
};