var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("li", {
    ref: "containerElement",
    staticClass: "app-menu-entry",
    class: {
      "app-menu-entry--active": _vm.app.active,
      "app-menu-entry--truncated": _setup.needsSpace
    }
  }, [_c("a", {
    staticClass: "app-menu-entry__link",
    attrs: {
      href: _vm.app.href,
      title: _vm.app.name,
      "aria-current": _vm.app.active ? "page" : false,
      target: _vm.app.target ? "_blank" : undefined,
      rel: _vm.app.target ? "noopener noreferrer" : undefined
    }
  }, [_c(_setup.AppMenuIcon, {
    staticClass: "app-menu-entry__icon",
    attrs: {
      app: _vm.app
    }
  }), _vm._v(" "), _c("span", {
    ref: "labelElement",
    staticClass: "app-menu-entry__label"
  }, [_vm._v("\n\t\t\t" + _vm._s(_vm.app.name) + "\n\t\t")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };