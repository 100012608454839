var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("NcHeaderMenu", {
    staticClass: "account-menu",
    attrs: {
      id: "user-menu",
      "is-nav": "",
      "aria-label": _vm.t("core", "Settings menu"),
      description: _vm.avatarDescription
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function () {
        return [_c("NcAvatar", {
          key: String(_vm.showUserStatus),
          staticClass: "account-menu__avatar",
          attrs: {
            "disable-menu": "",
            "disable-tooltip": "",
            "show-user-status": _vm.showUserStatus,
            user: _vm.currentUserId,
            "preloaded-user-status": _vm.userStatus
          }
        })];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("ul", {
    staticClass: "account-menu__list"
  }, [_c("AccountMenuProfileEntry", {
    attrs: {
      id: _vm.profileEntry.id,
      name: _vm.profileEntry.name,
      href: _vm.profileEntry.href,
      active: _vm.profileEntry.active
    }
  }), _vm._v(" "), _vm._l(_vm.otherEntries, function (entry) {
    return _c("AccountMenuEntry", {
      key: entry.id,
      attrs: {
        id: entry.id,
        name: entry.name,
        href: entry.href,
        active: entry.active,
        icon: entry.icon
      }
    });
  })], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };