// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-13b715c4] #header-menu-user-menu {
  padding: 0 !important;
}
.account-menu[data-v-13b715c4] button {
  opacity: 1 !important;
}
.account-menu[data-v-13b715c4] button:focus-visible .account-menu__avatar {
  border: var(--border-width-input-focused) solid var(--color-background-plain-text);
}
.account-menu[data-v-13b715c4] .header-menu__content {
  width: fit-content !important;
}
.account-menu__avatar[data-v-13b715c4]:hover {
  border: var(--border-width-input-focused) solid var(--color-background-plain-text);
}
.account-menu__list[data-v-13b715c4] {
  display: inline-flex;
  flex-direction: column;
  padding-block: var(--default-grid-baseline) 0;
  padding-inline: 0 var(--default-grid-baseline);
}
.account-menu__list[data-v-13b715c4] >  li {
  box-sizing: border-box;
  flex: 0 1;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
