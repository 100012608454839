var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("nav", {
    ref: "appMenu",
    staticClass: "app-menu",
    attrs: {
      "aria-label": _vm.t("core", "Applications menu")
    }
  }, [_c("ul", {
    staticClass: "app-menu__list",
    attrs: {
      "aria-label": _vm.t("core", "Apps")
    }
  }, _vm._l(_vm.mainAppList, function (app) {
    return _c("AppMenuEntry", {
      key: app.id,
      attrs: {
        app: app
      }
    });
  }), 1), _vm._v(" "), _c("NcActions", {
    staticClass: "app-menu__overflow",
    attrs: {
      "aria-label": _vm.t("core", "More apps")
    }
  }, _vm._l(_vm.popoverAppList, function (app) {
    return _c("NcActionLink", {
      key: app.id,
      staticClass: "app-menu__overflow-entry",
      attrs: {
        "aria-current": app.active ? "page" : false,
        href: app.href,
        icon: app.icon
      }
    }, [_vm._v("\n\t\t\t" + _vm._s(app.name) + "\n\t\t")]);
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };