// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-menu-entry__icon[data-v-0a2ba396] {
  height: 16px;
  width: 16px;
  margin: calc((var(--default-clickable-area) - 16px) / 2);
  filter: var(--background-invert-if-dark);
}
.account-menu-entry__icon--active[data-v-0a2ba396] {
  filter: var(--primary-invert-if-dark);
}
.account-menu-entry[data-v-0a2ba396] .list-item-content__main {
  width: fit-content;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
