// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactsmenu[data-v-f3f9ac60] {
  overflow-y: hidden;
}
.contactsmenu__trigger-icon[data-v-f3f9ac60] {
  color: var(--color-background-plain-text) !important;
}
.contactsmenu__menu[data-v-f3f9ac60] {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 328px;
  max-height: inherit;
}
.contactsmenu__menu label[for=contactsmenu__menu__search][data-v-f3f9ac60] {
  font-weight: bold;
  font-size: 19px;
  margin-inline-start: 13px;
}
.contactsmenu__menu__input-wrapper[data-v-f3f9ac60] {
  padding: 10px;
  z-index: 2;
  top: 0;
}
.contactsmenu__menu__search[data-v-f3f9ac60] {
  width: 100%;
  height: 34px;
  margin-top: 0 !important;
}
.contactsmenu__menu__content[data-v-f3f9ac60] {
  overflow-y: auto;
  margin-top: 10px;
  flex: 1 1 auto;
}
.contactsmenu__menu__content__footer[data-v-f3f9ac60] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contactsmenu__menu a[data-v-f3f9ac60]:focus-visible {
  box-shadow: inset 0 0 0 2px var(--color-main-text) !important;
}
.contactsmenu[data-v-f3f9ac60] .empty-content {
  margin: 0 !important;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
